/* eslint-disable no-loop-func */
import React, {ReactNode, useContext, useEffect, useRef, useState} from 'react'
import BasicTable from '../../../components/common/BasicTable'
import {ActionType, BasicTableModalRef} from '../../../components/common/BasicTableModal'
import DataModal from './components/DataModal'
import dayjsKorean from '../../../libs/DayjsKorean'
import {ArrowLeftOutlined, FolderOutlined, PaperClipOutlined, PushpinOutlined, RightOutlined} from '@ant-design/icons'
import {Breadcrumb, Space, Spin} from 'antd'
import {useNavigate, useParams} from 'react-router'
import {IDataGroup, getDataGroups} from '../../../api/dataGroups'
import PageContext from '../../../contexts/PageContext'
import ImageView from '../../../components/ImageView'
import withPageContext from '../../../hocs/withPageContext'
import {getDatas} from '../../../api/datas'

function DataItem() {
  const {state, apiHandler} = useContext(PageContext)
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const modalRef = useRef<BasicTableModalRef>(null)
  const [list, setList] = useState<any>([])
  const [folderDataSource, setFolderDataSource] = useState<IDataGroup[]>([])
  const [breadCrumbArr, setBreadCrumbArr] = useState<{id: number; title: string | ReactNode; image: string}[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {id} = useParams()
  const navigate = useNavigate()

  async function importDataItem() {
    setIsLoading(true)
    if (id) {
      let breadCrumbArr: {id: number; title: string | ReactNode; image: string}[] = []
      const {data} = await getDataGroups()

      const targetFolder = data.find(({id: folderId}) => +id === folderId)

      if (targetFolder) {
        breadCrumbArr = [{id: targetFolder.id, title: targetFolder.name, image: targetFolder.image}]
      }

      while (1) {
        const targetFolder = data.find(({id: folderId}) => breadCrumbArr[0].id === folderId)
        if (targetFolder?.parentId) {
          const parentFolder = data.find(({id: folderId}) => +targetFolder.parentId === folderId)
          if (parentFolder)
            breadCrumbArr = [
              {id: parentFolder.id, title: parentFolder.name, image: targetFolder.image},
              ...breadCrumbArr
            ]
          else break
        } else break
      }

      setBreadCrumbArr(breadCrumbArr)
    }
    setIsLoading(false)
  }

  async function addChildrenToParent() {
    const {data} = await getDataGroups()
    setFolderDataSource([
      {id: -1, name: '..', image: '', parentId: '-1', createdAt: '', updatedAt: '', sortOrder: -1, data: []},
      ...(data.filter(({parentId}) => `${parentId}` === id) as IDataGroup[])
    ])
  }

  const columns = [
    {
      dataIndex: 'isFixation',
      render: (isFixation) => (isFixation ? <PushpinOutlined style={{color: '#d54d43'}} /> : <></>),
      width: 30
    },
    {
      dataIndex: 'title',
      title: <div style={{textAlign: 'center'}}>제목</div>,
      render: (title, {isFixation, files}) =>
        isFixation ? (
          <Space>
            <strong style={{color: '#d54d43'}}>{title}</strong>
            {!!files?.length && <PaperClipOutlined />}
          </Space>
        ) : (
          <Space>
            <div>{title}</div>
            {!!files?.length && <PaperClipOutlined />}
          </Space>
        )
    },
    {
      title: <div style={{textAlign: 'center'}}>등록일</div>,
      dataIndex: 'createdAt',
      render: (text: string) => <div style={{textAlign: 'center'}}>{dayjsKorean(text).format('YYYY/MM/DD')}</div>,
      width: 300
    },
    {
      title: <div style={{textAlign: 'center'}}>수정일</div>,
      dataIndex: 'updatedAt',
      render: (text: string) => <div style={{textAlign: 'center'}}>{dayjsKorean(text).format('YYYY/MM/DD')}</div>,
      width: 300
    }
  ]

  const folderColumns = [
    {
      width: 30,
      dataIndex: 'image',
      render: (image, {name}: {name: string}) => (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {image ? (
            <ImageView src={image} width={50} height={50} />
          ) : (
            <div style={{width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              {name === '..' ? <ArrowLeftOutlined /> : <FolderOutlined style={{fontSize: 20, color: 'gray'}} />}
            </div>
          )}
        </div>
      )
    },
    {
      dataIndex: 'name',
      title: <div style={{textAlign: 'center'}}>폴더명</div>,
      render: (name, {data}) => (
        <Space>
          <div>{name}</div>
        </Space>
      )
    },
    {
      title: <div style={{textAlign: 'center'}}>등록일</div>,
      dataIndex: 'createdAt',
      render: (text: string, {name}: {name: string}) =>
        name === '..' ? <></> : <div style={{textAlign: 'center'}}>{dayjsKorean(text).format('YYYY/MM/DD')}</div>,
      width: 300
    },
    {
      title: <div style={{textAlign: 'center'}}>수정일</div>,
      dataIndex: 'updatedAt',
      render: (text: string, {name}: {name: string}) =>
        name === '..' ? <></> : <div style={{textAlign: 'center'}}>{dayjsKorean(text).format('YYYY/MM/DD')}</div>,
      width: 300
    }
  ]

  function showTableModal(e, data?, index?) {
    if (modalRef && modalRef.current) {
      if (data) modalRef.current.showModal({type: 'show', record: data})
      else modalRef.current.showModal({type: 'add'})
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  useEffect(() => {
    if (Array.isArray(state.data.data)) {
      const tmp = [...state.data.data]
      const fixationed = tmp.filter(({isFixation}) => isFixation)
      const disfixationed = state.data.data.filter(({isFixation}) => !isFixation)
      setList([
        ...fixationed.sort((a, b) => a.sortOrder - b.sortOrder),
        ...disfixationed.sort((a, b) => a.sortOrder - b.sortOrder)
      ])
    }
  }, [state.data])

  useEffect(() => {
    importDataItem()
    addChildrenToParent()
  }, [id])

  return (
    <>
      <Breadcrumb separator={<RightOutlined />} style={{fontSize: 20, fontWeight: 'bold', marginBottom: 20}}>
        <Breadcrumb.Item onClick={() => navigate('/console/data')}>
          <Space style={{cursor: 'pointer'}}>자료실</Space>
        </Breadcrumb.Item>
        {isLoading ? (
          <Spin />
        ) : (
          breadCrumbArr.map(({id, title, image}, index) => (
            <Breadcrumb.Item onClick={() => navigate('/console/data/' + id)}>
              <Space style={{cursor: 'pointer'}}>
                {!!image ? (
                  <ImageView src={image} width={30} height={30} />
                ) : (
                  <FolderOutlined style={{fontSize: 20, color: 'gray'}} />
                )}
                {title}
              </Space>
            </Breadcrumb.Item>
          ))
        )}
      </Breadcrumb>
      <BasicTable
        size="small"
        pagination={false}
        columns={folderColumns}
        loading={isLoading}
        dataSource={folderDataSource.sort((a, b) => a.sortOrder - b.sortOrder)}
        onRow={(record, rowIndex) => ({
          onClick: (e) => {
            record.id === -1
              ? navigate(
                  '/console/data' +
                    (breadCrumbArr[breadCrumbArr.length - 2] ? '/' + breadCrumbArr[breadCrumbArr.length - 2].id : '')
                )
              : navigate('/console/data/' + record.id)
          }
        })}
      />
      <div style={{height: 50}} />
      {/* {folderDataSource.length < 2 && ( */}
      <BasicTable
        showSearch
        searchPlaceHolder="이름으로 검색"
        size="small"
        rowKey="id"
        columns={columns}
        loading={isLoading}
        dataSource={list.filter(({status}) => status !== 'hidden')}
        total={list.filter(({status}) => status !== 'hidden').length}
        onRow={(record, rowIndex) => ({
          onClick: (e) => {
            setModalActions(['show'])
            showTableModal(e, record, rowIndex)
          }
        })}
      />
      {/* )} */}
      <DataModal actions={modalActions} ref={modalRef} onAction={onModalAction} />
    </>
  )
}

export default withPageContext(DataItem, (params) =>
  getDatas({...params, dataGroupId: location.pathname.split('/')[location.pathname.split('/').length - 1]})
)
